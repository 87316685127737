import "../style/index.css";
import "../style/header.css";
import SPARK_BIZZ_LOGO from "../assets/spark-bizz.png";
import { BiMenuAltRight } from "react-icons/bi";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(false);
  const getMenuStyle = (menuOpened) => {
    if (document.documentElement.clientWidth <= 800) {
      return { right: !menuOpened && "-100%" };
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };
  const handleClick = (e, id) => {
    e.preventDefault();
    scrollToSection(id);
  };

  return (
    <section className="h-wapper" id="home">
      <div className="flexCenter paddings innerWidth h-container">
        <img src={SPARK_BIZZ_LOGO} alt="loading..." width={100} />
        {!location.pathname.includes("productDisplay") ? (
          <>
            {" "}
            <OutsideClickHandler onOutsideClick={() => setMenuOpened(false)}>
              <div
                className="flexCenter h-menu"
                style={getMenuStyle(menuOpened)}
              >
                <a href="/home" onClick={(e) => handleClick(e, "home")}>
                  Home
                </a>
                <a href="/products" onClick={(e) => handleClick(e, "products")}>
                  Products
                </a>
                <a
                  href="/our-value"
                  onClick={(e) => handleClick(e, "our-value")}
                >
                  Our Value
                </a>
                <a href="/about-us" onClick={(e) => handleClick(e, "about-us")}>
                  About Us
                </a>
                <button className="button">
                  <a
                    href="/contact-us"
                    onClick={(e) => handleClick(e, "contact-us")}
                  >
                    Contact Us
                  </a>
                </button>
              </div>
            </OutsideClickHandler>
            <div
              className="menu-icon"
              onClick={() => setMenuOpened((prev) => !prev)}
            >
              <BiMenuAltRight size={30} />
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
};
