import { useSwiper } from "swiper/react"
import "../style/product.css"

export const SliderButton=()=>{
const swiper =useSwiper();
    return(
    <div className="flexCenter p-buttons">
        <button onClick={()=>swiper.slidePrev()}>&lt;</button>
        <button onClick={()=>swiper.slideNext()}>&gt;</button>  
    </div>
)
}