import React from "react";
import "../style/footer.css";
import LOGO from "../assets/spark-bizz.png";

export const Footer = () => {
  const scrollToSection=(id)=>{
    const section = document.getElementById(id);
    section.scrollIntoView({behavior:'smooth'});
  }
  const handleClick=(e,id)=>{
    e.preventDefault();
    scrollToSection(id);
  }
  return (
    <section className="f-wapper" id="footer">
      <div className="paddings innerWidth flexCenter f-container">
        {/**left Side */}
        <div className="flexColStart f-left">
          <img src={LOGO} alt="" width={120} />
          <span className="secondaryText">
          Our goal is to enrich lives by crafting optimal digital environments, improving quality of life for all.
          </span>
        </div>
        {/**Right Side */}
        <div className="flexColStart f-right">
          <span className="primaryText">Our Address</span>
          <span className="secondaryText">Flat-1/C,8/2/20, Kolkata, West Bengal ,700028</span>
          <div className="flexCenter f-menu">
            <span><a href="/products" onClick={(e)=>{handleClick(e,"products")}}>Products</a></span>
            <span><a href="/our-value" onClick={(e)=>{handleClick(e,"our-value")}}>Value</a></span>
            <span ><a href="/contact-us" onClick={(e)=>{handleClick(e,"contact-us")}}>Contact</a></span>
            <span ><a href="/about-us"onClick={(e)=>{handleClick(e,"about-us")}}>About Us</a></span>
          </div>
        </div>
      </div>
    </section>
  );
};
