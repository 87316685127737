import CountUp from "react-countup";
import HERO_IMAGE from "../assets/websiteDesign.jpg";
import "../style/hero.css";
import { motion } from "framer-motion";
export const Hero = () => {
  return (
    <section className="hero-wapper" id="hero">
      <div className="paddings innerWidth flexCenter hero-container">
        {/**Left side */}
        <div className="hero-left">
          <div className="hero-title">
            <div className="orange-circle" />
            <motion.h1
              initial={{ y: "2rem", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 3, type: "spring"}}
            >
              Discover <br />
              Most Suitable <br /> digital Platform
            </motion.h1>
          </div>
          <div className="flexColStart hero-description">
            <span >Find varities of digital platform suits your business.</span>
            <span>
              Forget all the difficuties in taking your business online.
            </span>
          </div>
          <div className="flexCenter stats" style={{marginTop:"2rem"}}>
            <div className="flexColStart stat">
              <span>
                <CountUp start={900} end={1928} duration={4} delay={2} />
                <span>+</span>
              </span>
              <span className="secondaryText" style={{color:"white"}}>Satisfied Clients</span>
            </div>
            <div className="flexColStart stat">
              <span>
                <CountUp start={900} end={2000} duration={4} delay={2} />
                <span>+</span>
              </span>
              <span className="secondaryText" style={{color:"white"}}>Premium Design</span>
            </div>
            <div className="flexColStart stat">
              <span>
                <CountUp start={90} end={100} duration={4} delay={2} />
                <span>%</span>
              </span>
              <span className="secondaryText" style={{color:"white"}}>High-Quality Guarantee</span>
            </div>
          </div>
        </div>

        {/**Right side */}
        <div className="flex-center hero-right">
          <motion.div
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring"}}
            className="image-container"
          >
            <img src={HERO_IMAGE} alt="loading ..." />
          </motion.div>
        </div>
      </div>
    </section>
  );
};
