import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "../style/product.css";
import { SliderSetting } from "../utils/common";
import { SliderButton } from "./SliderButton";
import { data } from "../data/slider";
import { Link } from "react-router-dom";
import { ProductDisplay } from "./ProductDisplay";

export const Products = () => {
  return (
    <section className="p-wrapper" id="products">
      <div className="paddings innerWidth p-container">
        <div className="p-head flexColStart">
          <span className="orangeText">Best Choices</span>
          <span className="primaryText">Popular Products & Services</span>
        </div>
        <Swiper {...SliderSetting}>
          <SliderButton />
          {data.map((card, i) => {
            return (
              <SwiperSlide key={i}>
                <Link to={`/productDisplay/${card.name}`} className="p-card flexColStart" >
                  <img src={card.image} alt="Product" className="p-image" />
                  <span className="secondaryText p-price">
                  </span>
                  <span className="primaryText p-name" >{card.name}</span>
                  <span className="secondaryText p-detail">{card.detail}</span>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};
