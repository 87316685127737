import React from "react";
import "../style/getStarted.css";

export const GetStarted = () => {
  return (
    <section className="g-wrapper" id="about-us">
      <div className="paddings innerWidth g-container">
        <div className="flexColCenter inner-container">
          <span className="primaryText">Get started with Spark Bizz</span>
          <span className="secondaryText">
          Subscribe now to discover our incredibly attractive price quotes.
            <br />
            Find your best deal soon
          </span>
          <button className="button">
            <a href="mailto:kravi4350.com" className="link">Get Started</a>
            </button>
        </div>
      </div>
    </section>
  );
};
