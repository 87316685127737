import WEBSITE_GRAPHIC from "../assets/websiteDesign.jpg";
import MOBILE_GRAPHIC from "../assets/applicationDesign.jpg";
import GRAPHICS_GRAPHICS from "../assets/graphicsDesign.jpg";
import SOCIAL_MEDIA_GRAPHIC from "../assets/socialMediaGraphics.jpg";
import VIDEO_GRAPHICS from "../assets/videoEditing.jpg";
import PHOTO_GRAPHIC from "../assets/photoEditing.jpg";

export const data = [
  {
    name: "Website Building",
    price: "47,043",
    detail:
      "Transforming Ideas into Stunning Online Experiences with our Expertise",
    image: WEBSITE_GRAPHIC,
    list: [
      "Restaurants/Food Businesses",
      "Real Estate",
      "Education/Schools",
      "Healthcare/Pharmacy",
      "Technology/Software",
      "Others"
    ],
  },
  {
    name: "Mobile Application",
    price: "66,353",
    detail: "Delivering seamless solutions for Android & iOS solutions.",
    image: MOBILE_GRAPHIC,
    list: [
      "Restaurants/Food Businesses",
      "Real Estate",
      "Education/Schools",
      "Healthcare/Pharmacy",
      "Technology/Software",
      "Others"
    ],
  },
  {
    name: "Graphics Designing",
    price: "35,853",
    detail: "Empowering businesses with tailored graphic design solutions.",
    image: GRAPHICS_GRAPHICS,
    list: [
      "Restaurants/Food Businesses",
      "Real Estate",
      "Education/Schools",
      "Healthcare/Pharmacy",
      "Technology/Software",
      "Others"
    ],
  },
  {
    name: "Social Media Graphics",
    price: "66,353",
    detail:
      "Crafting captivating social media graphics to elevate your online presence and engagement.",
    image: SOCIAL_MEDIA_GRAPHIC,
    list: [
      "Restaurants/Food Businesses",
      "Real Estate",
      "Education/Schools",
      "Healthcare/Pharmacy",
      "Technology/Software",
      "Others"
    ],
  },
  {
    name: "Video Editing",
    price: "35,853",
    detail: "Refining videos with industry-leading tools.",
    image: VIDEO_GRAPHICS,
    list: [
      "Restaurants/Food Businesses",
      "Real Estate",
      "Education/Schools",
      "Healthcare/Pharmacy",
      "Technology/Software",
      "Others"
    ],
  },
  {
    name: "Photo Editing",
    price: "35,853",
    detail: "Refining photos with professional-grade tools.",
    image: PHOTO_GRAPHIC,
    list: [
      "Restaurants/Food Businesses",
      "Real Estate",
      "Education/Schools",
      "Healthcare/Pharmacy",
      "Technology/Software",
      "Others"
    ],
  },
];
