import TATA_CLIQ_LOGO from "../assets/tataCliq.png";
import CATORAMA_NATIVE_LOGO from "../assets/castoramaNative.png";
import BQ1_LOGO from "../assets/B&Q1.png";
import ABInBEV_LOGO from "../assets/ABInBevlogo.jpg";
import KINGFISHER_LOGO from "../assets/KingfisherBeerLogo.png";
import "../style/companies.css";
import Marquee from "react-fast-marquee"


export const Companies = () => {
  return (
    <section className="c-wrapper" id="companies" >
      <h1 className="primaryText flexCenter m-top-50">Trusted By Over 1,000 Businesses</h1>
      <div className="paddings innerWidth flexCenter c-container">
        <img src={TATA_CLIQ_LOGO} alt="loading..." />
        <img src={CATORAMA_NATIVE_LOGO} alt="loading"/>
        <img src={ABInBEV_LOGO} alt="loading" />
        <img src={BQ1_LOGO} alt="loading" />
        <img src={KINGFISHER_LOGO} alt="loading" />
      </div>
    </section>
  );
};
