import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { MdOutlineArrowDropDown } from "react-icons/md";

import data from "../data/accordian";
import "../style/value.css";
import HERO_IMAGE from "../assets/applicationDesign.jpg";

export const Value = () => {
  const [classNames, setClassNames] = useState(Array(data.length).fill(null));

  const handleAccordionItemClick = (index) => {
    setClassNames((prevClassNames) =>
      prevClassNames.map((className, i) => (i === index ? (className === "expanded" ? "collapsed" : "expanded") : className))
    );
  };

  return (
    <section className="v-wapper" id="our-value">
      <div className="paddings innerWidth flexCenter v-container">
        <div className="v-left">
          <div className="image-container">
            <img src={HERO_IMAGE} alt="" />
          </div>
        </div>
        <div className="flexColStart v-right">
          <span className="orangeText">Our Value</span>
          <span className="primaryText">Value We Give to You</span>
          <span className="secondaryText">
          We're always here to assist you with top-notch services.
            <br />
            "Enhancing lives through exceptional IT solutions!"
          </span>
          <Accordion className="accordion" allowMultipleExpanded={false} preExpanded={[0]}>
            {data.map((item, i) => (
              <AccordionItem className={`accordionItem ${classNames[i]}`} key={i} uuid={i}>
                <AccordionItemHeading>
                  <AccordionItemButton
                    className="flexCenter accordionButton"
                    onClick={() => handleAccordionItemClick(i)}
                  >
                    <div className="flexCenter icon">{item.icon}</div>
                    <span className="primaryText">{item.heading}</span>
                    <div className="flexCenter icon">
                      <MdOutlineArrowDropDown size={20} />
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="secondaryText">{item.detail}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};
