import React from "react";
import { Hero } from "../component/Hero";
import { Companies } from "../component/Companies";
import { Products } from "../component/Products";
import { Value } from "../component/Value";
import { Contact } from "../component/Contact";
import { GetStarted } from "../component/GetStarted";
import { Footer } from "../component/Footer";

export const Home = () => {
  return (
    <>
      <Hero />
      <Companies />
      <Products />
      <Value />
      <Contact />
      <GetStarted />
      <Footer />
    </>
  );
};
