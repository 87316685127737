import "./App.css";
import "./style/index.css";
import "./style/header.css";
import { Header } from "./component/Header";
import WHATSAPP_ICON from "./assets/whatsapp-icon.png";
import { Home } from "./conatiner/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ProductDisplay } from "./component/ProductDisplay";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const onChatHandler = () => {
    window.open(
      "https://wa.me/+917079831728?text=Hello How can I help you?",
      "_blank"
    );
  };
  return (
    <>
        <div className="App">
          <div>
            {!location.pathname.includes("productDisplay") ? (
              <div className="white-gradient"></div>
            ) : null}
            <Header />
          </div>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/productDisplay/:name" component={ProductDisplay} />
          </Switch>
          <div className="whatsappIcon" onClick={onChatHandler}>
            <img src={WHATSAPP_ICON} alt="loading" width={70} />
          </div>
        </div>
    </>
  );
}

export default App;
