import React, { useState } from "react";
import "../style/contact.css";
import { MdCall, MdEmail } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import "react-responsive-modal/styles.css";
import Box from "@mui/material/Box";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Grid,
  Button,
  Snackbar,
  SnackbarContent,
  Modal,
  Typography,
} from "@mui/material";

export const Contact = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "none", // No border
    boxShadow: 30,
    p: 4,
    outline: "none",
    "@media (max-width: 600px)": {
      // Small screen
      width: "90%",
      boxShadow: 10,
      border: "none", // No border
    },
    "@media (min-width: 601px) and (max-width: 1024px)": {
      // Medium screen
      width: "70%",
      boxShadow: 20,
      border: "none", // No border
    },
    "@media (min-width: 1025px)": {
      // Large screen
      width: "50%",
      boxShadow: 30,
      border: "none", // No border
    },
  };

  const handleSubmit = async () => {
    setLoading(true);
    const userData = { firstName, lastName, mobileNumber, email, message };
    try {
      const response = await fetch(
        "https://x3cmki431m.execute-api.us-east-1.amazonaws.com/users",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );
      if (response.ok) {
        setFirstName("");
        setLastName("");
        setMobileNumber("");
        setEmail("");
        setMessage("");
        setSuccessMessage(
          "Form submitted successfully. We'll connect with you soon!"
        );
      } else {
        setFirstName("");
        setLastName("");
        setMobileNumber("");
        setEmail("");
        setMessage("");
        setErrorMessage("Failed to submit form. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      setFirstName("");
      setLastName("");
      setMobileNumber("");
      setEmail("");
      setMessage("");
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleCallNow = () => {
    if (window.innerWidth <= 600) {
      window.location.href = "tel:+919098598648";
    } else {
      // Handle call for desktop or tablet
    }
  };

  const handleMailNow = () => {
    window.location.href = "mailto:customersupport@spark-bizz.com";
  };

  const handleChatNow = () => {
    window.open(
      "https://wa.me/+917079831728?text=Hello,Welcome to Spark Work! Let us ignite and drive your business forward with our expertise and solutions.",
      "_blank"
    );
  };

  return (
    <section className="c-wapper" id="contact-us">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="primaryText"
          >
            Contact
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please call us at +91-9098598648. We will be happy to assist you.
          </Typography>
        </Box>
      </Modal>

      <div className="paddings innerWidth flexCenter c-container">
        {/**left side */}
        <div className="flexColStart c-left">
          <span className="orangeText">Our Contact Us</span>
          <span className="primaryText">Easy to contact us</span>
          <span className="secondaryText">
            Just a click away! Contact us anytime for top-notch <br />
            business advice and reliable support.
          </span>

          <div className="flexColStart contactModes">
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="fexCenter icon">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Call</span>
                    <span className="secondaryText">+91-9098598648</span>
                  </div>
                </div>
                <div className="flexCenter button" onClick={handleOpen}>
                  Call Now
                </div>
              </div>
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="fexCenter icon">
                    <MdEmail size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Mail</span>
                    <span className="secondaryText">
                      customersupport@spark-bizz.com
                    </span>
                  </div>
                </div>
                <div className="flexCenter button" onClick={handleMailNow}>
                  Mail Now
                </div>
              </div>
            </div>
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="fexCenter icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Message</span>
                    <span className="secondaryText">+91-9098598648</span>
                  </div>
                </div>
                <div className="flexCenter button" onClick={handleChatNow}>
                  Chat Now
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**right-side */}
        <div className="c-right">
          <div className="flextColStart">
            <Box>
              <h4 className="primaryText">
                Just fill out the form; we'll reach you.
              </h4>
            </Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="first-name">First Name</InputLabel>
                  <Input
                    id="first-name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="last-name">Last Name</InputLabel>
                  <Input
                    id="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="mobile-number">Mobile Number</InputLabel>
                  <Input
                    id="mobile-number"
                    value={mobileNumber}
                    aria-describedby="mobileNumber-Text-Helper"
                    onChange={(e) => setMobileNumber(e.target.value)}
                    required
                  />
                </FormControl>
                <FormHelperText id="mobileNumber-Text-Helper">
                  We will never share your mobile Number
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    id="email"
                    aria-describedby="email-helper-text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <FormHelperText id="email-helper-text">
                    We'll never share your email.
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="message">Message</InputLabel>
                  <Input
                    id="message"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        open={!!successMessage || !!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={successMessage || errorMessage}
          style={{ backgroundColor: "#28a745" }}
        />
      </Snackbar>
    </section>
  );
};
