import React, { useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Link,
} from "@mui/material";
import {
  Restaurant,
  Home,
  School,
  LocalHospital,
  DesktopWindows,
  MoreVert,
} from "@mui/icons-material";
import { data } from "../data/slider";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const handleChatNow = () => {
  window.open(
    "https://wa.me/+917079831728?text=Hello,Welcome to Spark Work! Let us ignite and drive your business forward with our expertise and solutions.",
    "_blank"
  );
};

const IconComponents = {
  "Restaurants/Food Businesses": <Restaurant color="primary" />,
  "Real Estate": <Home color="primary" />,
  "Education/Schools": <School color="primary" />,
  "Healthcare/Pharmacy": <LocalHospital color="primary" />,
  "Technology/Software": <DesktopWindows color="primary" />,
  Others: <MoreVert color="primary" />,
};

const renderIcon = (category) => {
  return IconComponents[category] || <MoreVert />;
};

export const ProductDisplay = () => {
  const [dataSet, setDataSet] = useState({});
  const { name } = useParams();
  const history = useHistory();
  const formattedName = name.replace(/%20/g, " ");

  return (
    <Grid container spacing={2} mt={2} ml={2}>
      {data.map((d, e) => {
        if (d.name === formattedName) {
          return (
            <>
              <Grid item xs={12} md={6} style={{ textAlign: "left" }} key={e}>
                <Typography className="primaryText" variant="h3">
                  {d.name}
                </Typography>
                <Typography variant="h6" style={{ color: "orange" }}>
                  {d.detail}
                </Typography>
                <Divider></Divider>
                <Typography variant="subtitle1" mt={2} mb={2}>
                  Below list of top services that we provide for your business.
                </Typography>
                <Divider></Divider>
                <List style={{ maxHeight: 300, overflowY: "hidden" }}>
                  {d?.list?.map((e, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>{renderIcon(e)}</ListItemIcon>
                      <ListItemText primary={e} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} md={6} style={{textAlign:"justify"}}>
             
                <div style={{ marginTop: "1rem" ,textAlign:"center"}}>
                  <img
                    src={d.image}
                    style={{
                      width: "90%",
                      maxWidth: "480px",
                      borderRadius: "2%",
                    }}
                    alt="Product Image"
                  />
                  <Typography>{d.name}</Typography>
                </div>
                <div style={{display:"flex",justifyContent:"space-around",marginTop:"1rem"}}>
              <Button
                  variant="contained"
                  style={{ marginTop: "0.5rem" }}
                  onClick={handleChatNow}
                >
                  Talk to expert
                </Button>
                <Button variant="contained" onClick={() => history.push("/")}>
                  Back
                </Button>
              </div>
              </Grid>
            </>
          );
        } else {
          return null;
        }
      })}
    </Grid>
  );
};
