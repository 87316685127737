import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Premium quality.",
    detail:
      "Our top-tier IT solutions offer unmatched quality, guaranteeing optimal performance and reliability for your needs.",
  },
  {
    icon: <MdAnalytics />,
    heading: "We offer the best prices!",
    detail:
      "Get the best IT solutions at unbeatable prices. Quality service without the hefty cost.",
  },
  {
    icon: <MdCancel />,
    heading: "Prevent unstable prices",
    detail:
      "Our tailored IT solutions offer fixed-price options, providing stability and peace of mind, while delivering top-notch services tailored to your business needs.",
  },
];
export default data;